import React from "react"
import { Layout } from "../components/layout"
import SEO from "../components/seo"
import { Link } from "gatsby"
import { linkResolver } from '../utils/linkResolver'
import { graphql } from 'gatsby'
import Img from "gatsby-image"
import Button from "../components/button"

export const query = graphql`
query issuesQuery {
    prismic {
        allIssues(sortBy: literal_release_date_DESC) {
          edges {
            node {
              cover_image
              cover_imageSharp {
                childImageSharp {
                  fluid(maxWidth: 400, maxHeight: 250) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
              issue_theme
              literal_release_date
              identifier
              release_date
              contributors
              _meta {
                type
                uid
              }
            }
          }
        }
      }
  }
`

const issuesPage = ({ data }) => (
    <div className="body">
        <Layout>
            <SEO title="Issues" />
            <div data-w-id="63b8498e-1639-aebb-9ce4-f79485e68895" className="page">
                <section className="section">
                    <div className="page-heading-parent">
                        <h1>Latest Issues.</h1>
                        <div className="line grey"></div>
                    </div>
                    <div className="w-layout-grid preview-grid">
                        {data.prismic.allIssues.edges.map(({ node: issue }, i) => (
                            <div key={i} className="preview issues">
                                <Link to={linkResolver(issue._meta)} className="card archive w-inline-block">
                                    <Img fluid={issue.cover_imageSharp.childImageSharp.fluid} alt="" className="card-image" />
                                    <div className="card-info">
                                        <h4>{issue.identifier[0].text}</h4>
                                        <div>{issue.release_date[0].text}</div>
                                    </div>
                                </Link>
                                <div className="preview">
                                    <Link to={linkResolver(issue._meta)}>
                                        <div className="text-link w-inline-block">
                                            <h2 className="heading">{issue.issue_theme[0].text}</h2>
                                        </div>
                                    </Link>
                                    <h4>Contributors:</h4>
                                    <p>{issue.contributors[0].text}</p>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="support-block">
                        <h2>Student-run and student-led, we rely on your support.</h2>
                        <div className="button-parent page">
                            <Button name="Support Us" to="/help-out/" />
                        </div>
                    </div>
                </section>
            </div>
        </Layout>
    </div>
)
export default issuesPage